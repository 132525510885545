<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="1000"
    scrollable
    persistent
  >
    <ProjectForm
      :project="newProject"
      :errors="projectValidationErrors"
      :disabled="$store.getters.loading['post:api/projects']"
      @clear:errors="CLEAR_PROJECT_VALIDATION_ERRORS"
      @cancel="onCancel"
      @save="onSave"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import ProjectForm from '@/components/forms/ProjectForm';

export default {
  name: 'CreateProject',

  components: { ProjectForm },

  computed: {
    ...mapState('projects', ['newProject', 'projectValidationErrors', 'projectFilterParams']),
  },

  created() {
    this.SET_PROJECT_VALIDATION_ERRORS({});
  },

  methods: {
    ...mapActions('projects', ['storeProject']),
    ...mapMutations('projects', [
      'SET_NEW_PROJECT',
      'SET_PROJECT_VALIDATION_ERRORS',
      'CLEAR_PROJECT_VALIDATION_ERRORS',
    ]),

    onCancel(project) {
      this.SET_NEW_PROJECT(project);
      this.goBack();
    },

    onSave(project) {
      this.storeProject(project).then((createdProject) => {
        this.$router.push({ name: 'project', params: { projectId: createdProject.id } });
      });
    },

    goBack() {
      this.$router.push(this.$route.meta.getBackRoute());
    },
  },
};
</script>
